import React, { Component } from 'react'
import auth from '../utils/auth';
import LoginForm from '../components/forms/login';
import Helmet from 'react-helmet';
import Icon from '../content/images/icon.png'
export default class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            user: {},
            auth: null,
            country: '',
            password: '',
            showMobileLogin: false,
        };
    }
    componentDidMount() {
        const token = auth.getToken();
        if (token) {
            const user = auth.getUserInfo();
            this.setState({user, auth: true})
        }
    }
    handleLoginSwitch(){
        const status = this.state.showMobileLogin;
        this.setState({showMobileLogin: !status})    
    }
    render() {
        return (
            <main className="min-h-screen bg-blue-secondary flex items-center">
                <Helmet title="Login | New Image™ Head Office" htmlAttributes={{lang: 'en'}}/>
                <div className="p-2 flex flex-wrap items-center max-w-md mx-auto border shadow-lg bg-white w-full rounded-lg" >
                    <div className="w-full">
                        <img src={Icon} alt="New_Image™ International" className="w-24 -mt-16 mx-auto"/>
                        <div className="p-10">
                            <LoginForm/>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}
