import React, { Component } from 'react'
import axios from 'axios';
import {Link, navigate} from 'gatsby';
import auth from '../../utils/auth';
import Loader from '../loader.js'
const API = process.env.API;
export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: '',
            rememberMe: false,
            loading: false,
        }
    }
    submitForm(e){
        e.preventDefault();
        this.setState({loading: true})
        const {email, password, rememberMe} = this.state;
        try{
            axios.post(`${API}/auth/local`, {
                identifier: email,
                password: password,
            })
            .then(res => {
                auth.setToken(res.data.jwt, rememberMe);
                auth.setUserInfo(res.data.user, rememberMe);
                navigate('/');
                this.setState({error: '', loading: false})
                

            })
            .catch((error) => {
                this.setState({error: 'The password or email you entered are incorrect', loading: false})
                console.log('error',error)
            });
        }
        catch(error) {
            console.log(error)
            this.setState({error: 'An unknown error occured', loading: false})
        }
    }
    render() {
        const checkboxStyle = {
            width: '30px',
            height: '30px',
            borderRadius: '20px',
            background: 'green' 
        };
        const {email, password, error, rememberMe, loading} = this.state;
        return (
            <main>
                <h1 className="font-medium text-brand-blue mb-2 text-2xl text-center">Sign in</h1>
                <form onSubmit={this.submitForm.bind(this)}>
                    
                    <div className="my-4">
                        <label htmlFor="email" className="font-semibold text-gray-700 inline-block mb-2">Email:</label>
                        <input type="email" 
                            onChange={(e)=>{this.setState({email: e.target.value})}} 
                            value={email}
                            name="email"
                            id="email"
                            required
                            className="bg-gray-100 focus:bg-white rounded border-2 block w-full focus:border-blue-400 focus:outline-none py-2 px-3 leading-tight focus:shadow" 
                        />
                    </div>
                    <div className="my-4">
                        <label htmlFor="password" className="font-semibold text-gray-700 inline-block mb-2">Password:</label>
                        <input type="password" 
                            onChange={(e)=>{this.setState({password: e.target.value})}} 
                            value={password}
                            name="password"
                            id="password"
                            required
                            className="bg-gray-100 focus:bg-white rounded border-2 block w-full focus:border-blue-400 focus:outline-none py-2 px-3 leading-tight focus:shadow" 
                        />
                    </div>
                    <div className="flex items-center my-4">
                        <div className="relative mr-2 block mt-1">
                            <input type="checkbox" 
                                onChange={(e)=>{this.setState({rememberMe: e.target.checked})}} 
                                value={rememberMe} 
                                style={checkboxStyle}
                                name="remember-me"
                                id="remember-me"
                                className="rounded opacity-0 relative z-20"
                            />
                            {rememberMe?
                                <span className="absolute left-0 top-0 bg-brand-blue rounded h-6 w-6 inline-block z-10 text-center">
                                    <span className="flaticon-tick-sign text-white"></span>
                                </span>
                                :
                                <span className="absolute left-0 top-0 bg-gray-200 border rounded h-6 w-6 inline-block z-10">
                                    <span></span>
                                </span>
                            }
                            
                        </div>
                        <label htmlFor="remember-me" className="text-gray-700 font-semibold block">Remember me</label>
                    </div>
                    <button type="submit" className="bg-brand-blue text-white rounded py-2 px-8 mr-3 w-full" id="submit">
                        Sign in
                        {loading&& <Loader/>}
                    </button>
                    <p className="text-red-700 font-semibold mt-4">
                        {error}
                    </p>
                    <div className="text-center mt-4">
                        <Link to="/forgot-password" className="font-semibold text-brand-blue" id="forgot-password-link">
                            Forgot Password?
                        </Link>
                    </div>
                </form>
            </main>
        )
    }
}